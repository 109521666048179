import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type14AI, Type14AIType } from "./Type14AI";
import { Type14AIService } from "./type14AI.service";
import { Type14AIComponent } from "./type14AI.component";
import { createType14AIValidators } from "./Type14AIValidation";
import { ObjectReference } from "@xo/services";

@Component({
  selector: "spec-app-type14-a-i-page",
  templateUrl: "./type14AI-page.component.html",
  styleUrls: ["./type14AI-page.component.scss"]
})
export class Type14AIPage extends ObjectWindow<Type14AI, Type14AIService> {

  @ViewChild("component", { static: false }) component: Type14AIComponent;

  Type14AI = Type14AI;

  public newIncludedLink: ObjectReference;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type14AIType) as Type14AIService, toastrService, createType14AIValidators(), changeDetectorRef);
  }

  setupNewObject(rawType14AI) {
    let type14AI = Object.assign(new Type14AI(), rawType14AI);
    return type14AI;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }
}
