import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type10Mixed } from "./Type10Mixed";
import { ViewChild } from "@angular/core";
import { Type10MixedReference } from "../type10mixed/Type10Mixed";
import { PageService, FilterObject } from "@xo/client-common";
import { Type2LinkTable } from "../type2link/type2Link.table";

@Component({
  selector: "spec-app-type10-mixed",
  templateUrl: "./type10Mixed.component.html",
  styleUrls: ["./type10Mixed.component.scss"]
})
export class Type10MixedComponent extends ObjectComponent {

  public Type10Mixed = Type10Mixed;
  @Input() public type10Mixed: Type10Mixed;
  @Input() public title: string;

  @ViewChild("type2LinksWithMixed", { static: false }) type2LinksWithMixed: Type2LinkTable;
  type2LinkMixedFilter: FilterObject;


  constructor(public pageService: PageService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
    this.reloadTables();
  }

  initFilters() {
    this.type2LinkMixedFilter = { mixed: this.type10Mixed.id } as FilterObject;
  }

  async reloadTables() {
    this.tab.title = `Type10Mixed at ID:${this.type10Mixed ? this.type10Mixed.id : null}`;
    this.initFilters();
    await this.type2LinksWithMixed.init({});
  }

  createClickHandleForType2LinkMixed() {
    return this.pageService.createOpenObject(
      "SpecApp/Type2LinkPage",
      this.tab.system,
      this.tab.tab?.deck,
      (object) => `Type2Link at ID:${object ? object.id : null}`,
      () => {
        return {
          mixed: new Type10MixedReference(this.type10Mixed.id, this.type10Mixed.stringField)
        };
      }
    );
  }
}
