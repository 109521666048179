import { ObjectReference, Node, ColumnInfo } from "@xo/services";
import { Type2Link } from "../type2link/Type2Link";
import { Type13Code } from "../type13code/Type13Code";
import { Type14AI } from "../type14ai/Type14AI";


export class Type1BaseReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let Type1BaseType = "Type1Base";

export class Type1Base extends Node {

    constructor() {
        super(Type1BaseType, "SpecApp/SimpleType1BaseComponent");
    }

    get label() {
        return this.name;
    }

    set label(label) {
        this.name = label;
    }

    classes: string;

    public static columnInfo = [
        new ColumnInfo("Name", "name", "string", "None"),
    ];

    public id: number;
    public name: string;
    public type2Links: Type2Link[] = [];
    //TODO public type2Links: Type2Link[] = [];
    //TODO public type2Links: Type2Link[] = [];
    public type13Codes: Type13Code[] = [];
    //TODO public type13Codes: Type13Code[] = [];
    public type14AIs: Type14AI[] = [];
}
