import { DataService, CachingDataService, Transformator, ApiClient } from "@xo/services";
import { Type14AIUrlService } from "./type14ai.url.service";
import { Type14AI } from "./Type14AI";
import { Type1BaseReference } from "../type1base/Type1Base";

export class Type14AITransformator extends Transformator<Type14AI> {

    async transformFromServer(rawType14AI: any, localType14AI?: Type14AI): Promise<Type14AI> {
        let result: Type14AI = localType14AI ? localType14AI : new Type14AI();
        result = Object.assign(result, rawType14AI);
        result.includedLink = rawType14AI.includedLink ?
            rawType14AI.includedLink.map(
                (arrayMember) => {
                    return new Type1BaseReference(arrayMember.id, arrayMember.label);
                })
            : [];
        return result;
    }

    transformToServer(type14AI: Type14AI): any {
        let result: any = Object.assign({}, type14AI);
        return result;
    }
}

export class Type14AIService extends DataService<Type14AI> {

    constructor(http: ApiClient, type14AIUrlService: Type14AIUrlService, type14AITransformator: Type14AITransformator) {
        super(http, type14AIUrlService, type14AITransformator, "id");
    }
}

export class CachingType14AIService extends CachingDataService<Type14AI> {

    constructor(http: ApiClient, type14AIUrlService: Type14AIUrlService, type14AITransformator: Type14AITransformator) {
        super(false, http, type14AIUrlService, type14AITransformator, "id");
    }
}
