import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type2LongString } from "./Type2LongString";

@Component({
  selector: "spec-app-type2-long-string",
  templateUrl: "./type2LongString.component.html",
  styleUrls: ["./type2LongString.component.scss"]
})
export class Type2LongStringComponent extends ObjectComponent {

  public Type2LongString = Type2LongString;
  @Input() public type2LongString: Type2LongString;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type2LongString at ID:${this.type2LongString ? this.type2LongString.id : null}`;
  }
}
