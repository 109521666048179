import { DataService, CachingDataService, Transformator, ApiClient, transformDateArrayFromServer, transformDateArrayToServer } from "@xo/services";
import { Type13CodeUrlService } from "./type13code.url.service";
import { Type13Code } from "./Type13Code";
import { Type1BaseReference } from "../type1base/Type1Base";

export class Type13CodeTransformator extends Transformator<Type13Code> {

    async transformFromServer(rawType13Code: any, localType13Code?: Type13Code): Promise<Type13Code> {
        let result: Type13Code = localType13Code ? localType13Code : new Type13Code();
        result = Object.assign(result, rawType13Code);
        if (!result.stringArray) {
            result.stringArray = [];
        }
        result.dateArray = transformDateArrayFromServer(rawType13Code.dateArray);
        result.base = rawType13Code.base ? new Type1BaseReference(rawType13Code.base.id, rawType13Code.base.label) : null;
        result.baseArray = rawType13Code.baseArray ?
            rawType13Code.baseArray.map(
                (arrayMember) => {
                    return new Type1BaseReference(arrayMember.id, arrayMember.label);
                })
            : [];
        return result;
    }

    transformToServer(type13Code: Type13Code): any {
        let result: any = Object.assign({}, type13Code);
        result.dateArray = transformDateArrayToServer(type13Code.dateArray);
        return result;
    }
}

export class Type13CodeService extends DataService<Type13Code> {

    constructor(http: ApiClient, type13CodeUrlService: Type13CodeUrlService, type13CodeTransformator: Type13CodeTransformator) {
        super(http, type13CodeUrlService, type13CodeTransformator, "code");
    }
}

export class CachingType13CodeService extends CachingDataService<Type13Code> {

    constructor(http: ApiClient, type13CodeUrlService: Type13CodeUrlService, type13CodeTransformator: Type13CodeTransformator) {
        super(false, http, type13CodeUrlService, type13CodeTransformator, "code");
    }
}
