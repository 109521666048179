import { Component, ViewChild, ChangeDetectorRef, Optional } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ObjectWindow, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type1Base, Type1BaseType } from "./Type1Base";
import { Type1BaseService } from "./type1Base.service";
import { Type1BaseComponent } from "./type1Base.component";
import { createType1BaseValidators } from "./Type1BaseValidation";

@Component({
  selector: "spec-app-type1-base-page",
  templateUrl: "./type1Base-page.component.html",
  styleUrls: ["./type1Base-page.component.scss"]
})
export class Type1BasePage extends ObjectWindow<Type1Base, Type1BaseService> {

  @ViewChild("component", { static: false }) component: Type1BaseComponent;

  Type1Base = Type1Base;

  constructor(changeDetectorRef: ChangeDetectorRef,
    toastrService: ToastrService,
    @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {

    super((tab || deckTab).getService(Type1BaseType) as Type1BaseService, toastrService, createType1BaseValidators(), changeDetectorRef);
  }

  setupNewObject(rawType1Base) {
    let type1Base = Object.assign(new Type1Base(), rawType1Base);
    return type1Base;
  }

  async reload() {
    await super.reload();
    if (!this.id) {
      return;
    }
    this.object = await this.dataService.load(this.id);
    if (!!this.component) {
      await this.component.reloadTables();
    }
  }

  async createDocument() {
    let document = await this.dataService.createDocument(this.object);
    alert(`${document.id} - ${document.name} created`);
  }

  async stream() {
    await this.dataService.stream(this.object);
  }

  async download() {
    let documentId = prompt("Enter document id", "1");
    await this.dataService.download(this.object, documentId);
  }
}
