import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type7Time } from "./Type7Time";

@Component({
  selector: "spec-app-type7-time",
  templateUrl: "./type7Time.component.html",
  styleUrls: ["./type7Time.component.scss"]
})
export class Type7TimeComponent extends ObjectComponent {

  public Type7Time = Type7Time;
  @Input() public type7Time: Type7Time;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type7Time at ID:${this.type7Time ? this.type7Time.id : null}`;
  }
}
