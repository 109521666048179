import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type5Boolean } from "./Type5Boolean";

@Component({
  selector: "spec-app-type5-boolean",
  templateUrl: "./type5Boolean.component.html",
  styleUrls: ["./type5Boolean.component.scss"]
})
export class Type5BooleanComponent extends ObjectComponent {

  public Type5Boolean = Type5Boolean;
  @Input() public type5Boolean: Type5Boolean;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type5Boolean at ID:${this.type5Boolean ? this.type5Boolean.id : null}`;
  }
}
