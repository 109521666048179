import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type11File } from "./Type11File";

@Component({
  selector: "spec-app-type11-file",
  templateUrl: "./type11File.component.html",
  styleUrls: ["./type11File.component.scss"]
})
export class Type11FileComponent extends ObjectComponent {

  public Type11File = Type11File;
  @Input() public type11File: Type11File;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type11File at ID:${this.type11File ? this.type11File.code : null}`;
  }
}
