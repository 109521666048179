import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent, ValidationMessage } from "@xo/client-common";
import { Type2Link } from "./Type2Link";
import { ModalService } from "@xo/client-common";
import { Type10Mixed, Type10MixedType } from "../type10mixed/Type10Mixed";
import { Type10MixedService } from "../type10mixed/type10Mixed.service";

@Component({
  selector: "spec-app-type2-link",
  templateUrl: "./type2Link.component.html",
  styleUrls: ["./type2Link.component.scss"]
})
export class Type2LinkComponent extends ObjectComponent {

  public Type2Link = Type2Link;
  @Input() public type2Link: Type2Link;
  @Input() public title: string;

  @Input() public mixedValidationMessages: ValidationMessage[];
  @Input() public mixedValidate;
  private type10MixedService: Type10MixedService;


  constructor(public modalService: ModalService, @Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
    this.type10MixedService = (tab || deckTab).getService(Type10MixedType);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type2Link at ID:${this.type2Link ? this.type2Link.id : null}`;
  }

  saveMixed(mixed: Type10Mixed) {
    this.type10MixedService.save(mixed);
  }
}
