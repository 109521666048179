import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type6Date } from "./Type6Date";

@Component({
  selector: "spec-app-type6-date",
  templateUrl: "./type6Date.component.html",
  styleUrls: ["./type6Date.component.scss"]
})
export class Type6DateComponent extends ObjectComponent {

  public Type6Date = Type6Date;
  @Input() public type6Date: Type6Date;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type6Date at ID:${this.type6Date ? this.type6Date.id : null}`;
  }
}
