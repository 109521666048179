import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent } from "@xo/client-common";
import { Type4Fraction } from "./Type4Fraction";

@Component({
  selector: "spec-app-type4-fraction",
  templateUrl: "./type4Fraction.component.html",
  styleUrls: ["./type4Fraction.component.scss"]
})
export class Type4FractionComponent extends ObjectComponent {

  public Type4Fraction = Type4Fraction;
  @Input() public type4Fraction: Type4Fraction;
  @Input() public title: string;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
  }

  async init() {
  }


  async reloadTables() {
    this.tab.title = `Type4Fraction at ID:${this.type4Fraction ? this.type4Fraction.id : null}`;
  }

  convertFraction(convertable: any) {
    return parseFloat(convertable.value);
  }
}
