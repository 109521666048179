import { Component, Input, Optional } from "@angular/core";
import { ObjectComponent, TabComponent, DeckTabComponent, ValidationMessage } from "@xo/client-common";
import { Type14AI } from "./Type14AI";
import { Type1Base, Type1BaseType } from "../type1base/Type1Base";
import { Type1BaseService } from "../type1base/type1Base.service";

@Component({
  selector: "spec-app-type14-a-i",
  templateUrl: "./type14AI.component.html",
  styleUrls: ["./type14AI.component.scss"]
})
export class Type14AIComponent extends ObjectComponent {

  public Type14AI = Type14AI;
  @Input() public type14AI: Type14AI;
  @Input() public title: string;

  @Input() public includedLinkValidationMessages: ValidationMessage[];
  @Input() public includedLinkValidate;
  private type1BaseService: Type1BaseService;


  constructor(@Optional() tab?: TabComponent, @Optional() deckTab?: DeckTabComponent) {
    super(tab || deckTab);
    this.type1BaseService = (tab || deckTab).getService(Type1BaseType) as Type1BaseService;
  }

  async init() {
    this.reloadTables();
  }


  async reloadTables() {
    this.tab.title = `Type14AI at ID:${this.type14AI ? this.type14AI.id : null}`;
  }

  saveIncludedLink(includedLink: Type1Base) {
    this.type1BaseService.save(includedLink);
  }
}
